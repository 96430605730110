import {
  Box,
  Button,
  Flashbar,
  FormField,
  Input,
  Link,
  Modal,
  Pagination,
  ProgressBar,
  Select,
  SpaceBetween,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { DelayOptions, DeviceMutationStatuses, DeviceSources, OnguardDelay, OnguardReaderMaskActionTypes, Statuses, URLS } from 'src/constants/Constants';
import React, { useEffect, useState } from 'react';
import { debug, validEmailAddresses } from '../utils/commonUtils';
import { selectUsername, selectUserSelectedSite } from '../stores/slices/userSlice';
import {
  setInputMask,
  setOnguardReaderMasking,
  setReaderDoorForcedMask,
  setReaderDoorHeldMask
} from 'src/utils/DevicesUtils';
import EmptyState from './EmptyState';
import { SilencioDevice } from "src/utils/SilencioTypes";
import TranslateText from './TranslateText';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useSelector } from 'react-redux';
import { ChildDevice } from 'src/API';

export default function ChangeStatus(props: any) {
  debug(`ChangeStatus() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.ChangeStatus');

  const [emailAddresses, setEmailAddresses] = useState(props.emailAddresses);
  const [confirmed, setConfirmed] = useState(false);
  const [maskedEvent, setMaskedEvent] = useState<any>(props.singleSelection ? { label: props.singleSelection?.selection.event, value: props.singleSelection?.selection.event } : null);
  const [newStatus, setNewStatus] = useState<any>(props.singleSelection?.selection);
  const [disableSelectMode, setDisableSelectMode] = useState<boolean>(false);
  const [delay, setDelay] = useState<any>({ label: 'None', value: '0' });
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [progressDescription, setProgressDescription] = useState('');
  const [selectedDevices, setSelectedDevices] = useState(
    props.singleSelection ? [{ ...props.singleSelection.device, setDeviceMaskStatus: DeviceMutationStatuses.notStarted }] :
      props.selectedDevices.map((sd: SilencioDevice) => {
        return {
          ...sd,
          setDeviceMaskStatus: DeviceMutationStatuses.notStarted
        }
      }));
  const [showProgress, setShowProgress] = useState(false);

  const username = useSelector(selectUsername);
  const userSelectedSite = useSelector(selectUserSelectedSite);

  const setDeviceMaskCallbackSuccess = (result: any) => {
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() result is ', result);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() selectedDevices is ', selectedDevices);
    const selectedDevicesCopy = [...selectedDevices];
    const updatedSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result.devices[0].deviceName);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() updatedSelectedDeviceIndex is ', updatedSelectedDeviceIndex);
    const updatedSelectedDevice = selectedDevicesCopy[updatedSelectedDeviceIndex];
    if (result.status === 200 && result.devices[0].status === 'Success') {
      console.log('ChangeStatus() setDeviceMaskCallbackSuccess() newStatus is ', newStatus);
      updatedSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.updated;
      if (props.deviceType == 'inputs')
        updatedSelectedDevice.Masked = (newStatus.value === 'Masked');
      if (props.deviceType == 'readersAlarms') {
        if (maskedEvent.value == 'Door Forced Open')
          updatedSelectedDevice.DoorForcedMasked = (newStatus.value === 'Masked');
        if (maskedEvent.value == 'Door Held Open')
          updatedSelectedDevice.DoorHeldMasked = (newStatus.value === 'Masked');
      }
      const updatedSelectedDeviceForCallback = { ...updatedSelectedDevice };
      delete updatedSelectedDeviceForCallback.setDeviceMaskStatus;
      console.log('ChangeStatus() setDeviceMaskCallbackSuccess() calling props.updateDeviceCallback() with ', updatedSelectedDeviceForCallback);
      props.updateDeviceCallback(updatedSelectedDeviceForCallback);
    } else {
      updatedSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.failure;
    }
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() updatedSelectedDevice is ', updatedSelectedDevice);
    selectedDevicesCopy.splice(updatedSelectedDeviceIndex, 1, updatedSelectedDevice);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
  };

  const setDeviceMaskCallbackFailure = (result: any) => {
    console.log('ChangeStatus() setDeviceMaskCallbackFailure() result is ', result);
    const selectedDevicesCopy = [...selectedDevices];
    const failedUpdateSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result.devices[0].deviceName);
    const failedUpdateSelectedDevice = selectedDevicesCopy[failedUpdateSelectedDeviceIndex];
    failedUpdateSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.failure;
    selectedDevicesCopy.splice(failedUpdateSelectedDeviceIndex, 1, failedUpdateSelectedDevice);
    console.log('ChangeStatus() setDeviceMaskCallbackFailure() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
  };

  const setDeviceMaskCallbackSuccessForDelay = (result: any) => {
    console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() result is ', result);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() selectedDevices is ', selectedDevices);
    const selectedDevicesCopy = [...selectedDevices];
    for (let result_device of result.devices) {
      console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() result_device is ', result_device);
      const updatedSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result_device.deviceName);
      console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() updatedSelectedDeviceIndex is ', updatedSelectedDeviceIndex);
      const updatedSelectedDevice = selectedDevicesCopy[updatedSelectedDeviceIndex];
      if (result.status === 202) updatedSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.submitted;
      if (result.status !== 202) updatedSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.failure;
      console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() updatedSelectedDevice is ', updatedSelectedDevice);
      selectedDevicesCopy.splice(updatedSelectedDeviceIndex, 1, updatedSelectedDevice);
      console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() selectedDevicesCopy is ', selectedDevicesCopy);
    }
    setSelectedDevices(selectedDevicesCopy);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccessForDelay() done with call to setSelectedDevices using ', selectedDevicesCopy);
    setProgressDescription(`Complete: Submitted Request ${result.requestUUID ? result.requestUUID : ''}`);
    setProgress(100);
    setInProgress(false);
  };

  const setDeviceMaskCallbackFailureForDelay = (result: any) => {
    console.log('ChangeStatus() setDeviceMaskCallbackFailureForDelay() result is ', result);
    const selectedDevicesCopy = [...selectedDevices];
    for (let result_device of result.devices) {
      const failedUpdateSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result_device.deviceName);
      const failedUpdateSelectedDevice = selectedDevicesCopy[failedUpdateSelectedDeviceIndex];
      failedUpdateSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.failure;
      selectedDevicesCopy.splice(failedUpdateSelectedDeviceIndex, 1, failedUpdateSelectedDevice);
    }
    console.log('ChangeStatus() setDeviceMaskCallbackFailureForDelay() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
    setProgressDescription(`Failure: Submitted Request ${result.requestUUID ? result.requestUUID : ''}`);
    setProgress(100);
    setInProgress(false);
  };

  const onguardSite: boolean = selectedDevices[0]?.devicesource === DeviceSources.ONGUARD;
  console.log('ChangeStatus() onguardSite is ', onguardSite);

  const processDevices = () => {
    if (inProgress) return;
    console.log('ChangeStatus() processDevices()');
    let updatedSelectedDevices: SilencioDevice[] = [];
    const selectedDevicesCopy = [...selectedDevices];
    console.log('ChangeStatus() processDevices() selectedDevicesCopy.length is ', selectedDevicesCopy.length);
    console.log('ChangeStatus() processDevices() selectedDevicesCopy is ', selectedDevicesCopy);
    console.log('ChangeStatus() processDevices() delay.value is ', delay.value);
    console.log('ChangeStatus() processDevices() onguardSite is ', onguardSite);
    if (delay.value > 0 || (props.deviceType === 'readersAlarms' && onguardSite)) {
      for (let i = 0; i < selectedDevicesCopy.length; i++) {
        const updatedSelectedDevice = selectedDevicesCopy[i];
        updatedSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.inProgress;
        updatedSelectedDevices = [...selectedDevicesCopy.filter((sd: SilencioDevice) => sd.DeviceName !== updatedSelectedDevice.DeviceName), updatedSelectedDevice];
      }
      const devices = selectedDevicesCopy.map((sd: any) => { return { deviceName: sd.DeviceName, deviceHref: sd.device_href } });
      console.log('ChangeStatus() processDevices() devices is ', devices);
      console.log('ChangeStatus() processDevices() props.deviceType is ', props.deviceType);
      if (props.deviceType === 'inputs') {
        setInputMask(
          selectedDevices[0].devicesource,
          devices,
          emailAddresses,
          (newStatus.value === 'Masked'),
          delay.value * 60,
          username,
          userSelectedSite.sitename,
          userSelectedSite.siteRegion)
          .then(setDeviceMaskCallbackSuccessForDelay, setDeviceMaskCallbackFailureForDelay);
      }
      if (props.deviceType === 'readersAlarms') {
        console.log('ChangeStatus() processDevices() maskedEvent.value is ', maskedEvent.value);
        if (maskedEvent.value === 'Door Forced Open')
          if (onguardSite) {
            setOnguardReaderMasking(
              userSelectedSite.siteRegion,
              selectedDevicesCopy,
              OnguardReaderMaskActionTypes.doorForcedOpen,
              (newStatus.value === 'Masked'),
              username,
              OnguardDelay,
              emailAddresses,
              userSelectedSite.sitename)
              .then(setDeviceMaskCallbackSuccessForDelay, setDeviceMaskCallbackFailure);
          } else {
            setReaderDoorForcedMask(
              selectedDevices[0].devicesource,
              devices,
              emailAddresses,
              (newStatus.value === 'Masked'),
              delay.value * 60,
              username,
              userSelectedSite.sitename,
              userSelectedSite.siteRegion)
              .then(setDeviceMaskCallbackSuccessForDelay, setDeviceMaskCallbackFailure);
          }
        if (maskedEvent.value === 'Door Held Open')
          if (onguardSite) {
            setOnguardReaderMasking(
              userSelectedSite.siteRegion,
              selectedDevicesCopy,
              OnguardReaderMaskActionTypes.doorHeldOpen,
              (newStatus.value === 'Masked'),
              username,
              OnguardDelay,
              emailAddresses,
              userSelectedSite.sitename)
              .then(setDeviceMaskCallbackSuccessForDelay, setDeviceMaskCallbackFailure);
          } else {
            setReaderDoorHeldMask(
              selectedDevices[0].devicesource,
              devices,
              emailAddresses,
              (newStatus.value === 'Masked'),
              delay.value * 60,
              username,
              userSelectedSite.sitename,
              userSelectedSite.siteRegion)
              .then(setDeviceMaskCallbackSuccessForDelay, setDeviceMaskCallbackFailure);
          }
      }
    } else {
      for (let i = 0; i < selectedDevicesCopy.length; i++) {
        if (!selectedDevicesCopy[i].DeviceName || !selectedDevicesCopy[i].devicesource) continue;
        const deviceHref = selectedDevicesCopy[i].device_href === 'Not_Available' ? undefined : selectedDevicesCopy[i].device_href;
        const devices = [{ deviceName: selectedDevices[i].DeviceName, deviceHref: deviceHref }];
        if (props.deviceType == 'inputs')
          setInputMask(selectedDevicesCopy[i].devicesource!, devices, '', (newStatus.value === 'Masked'), 0, username, userSelectedSite.sitename, userSelectedSite.siteRegion)
            .then(setDeviceMaskCallbackSuccess, setDeviceMaskCallbackFailure);
        if (props.deviceType == 'readersAlarms') {
          if (maskedEvent?.value == 'Door Forced Open')
            setReaderDoorForcedMask(selectedDevicesCopy[i].devicesource!, devices, '', (newStatus.value === 'Masked'), 0, username, userSelectedSite.sitename, userSelectedSite.siteRegion)
              .then(setDeviceMaskCallbackSuccess, setDeviceMaskCallbackFailure);
          if (maskedEvent?.value == 'Door Held Open')
            setReaderDoorHeldMask(selectedDevicesCopy[i].devicesource!, devices, '', (newStatus.value === 'Masked'), 0, username, userSelectedSite.sitename, userSelectedSite.siteRegion)
              .then(setDeviceMaskCallbackSuccess, setDeviceMaskCallbackFailure);
        }
        const updatedSelectedDevice = selectedDevicesCopy[i];
        updatedSelectedDevice.setDeviceMaskStatus = DeviceMutationStatuses.inProgress;
        updatedSelectedDevices = [...selectedDevicesCopy.filter((sd: SilencioDevice) => sd.DeviceName !== updatedSelectedDevice.DeviceName), updatedSelectedDevice];
      }
    }
    console.log('ChangeStatus() processDevices() updatedSelectedDevices is ', updatedSelectedDevices);
    setSelectedDevices(updatedSelectedDevices);
  };

  const confirmBtnHandler = () => {
    console.log('ChangeStatus() confirmBtnHandler()');
    setDisableSelectMode(true);
    setProgress(0);
    setInProgress(true);
    setConfirmed(true);
    setShowProgress(true);
    processDevices();
  };

  useEffect(() => {
    if (progress >= 100 || delay.value > 0) return;
    debug(`ChangeStatus() useEffect() [showProgress, selectedDevices] progress is ${progress} showProgress is ${showProgress}`);
    debug(`ChangeStatus() useEffect() [showProgress, selectedDevices] selectedDevices is ${JSON.stringify(selectedDevices)}`);
    if (showProgress) {
      debug(`ChangeStatus() useEffect() [showProgress, selectedDevices] selectedDevices.length is ${selectedDevices.length}`);
      let processedCount = 0;
      for (let i = 0; i < selectedDevices.length; i++) {
        if (selectedDevices[i].setDeviceMaskStatus === DeviceMutationStatuses.updated ||
          selectedDevices[i].setDeviceMaskStatus === DeviceMutationStatuses.failure ||
          selectedDevices[i].setDeviceMaskStatus === DeviceMutationStatuses.submitted)
          processedCount++;
      }
      debug(`ChangeStatus() useEffect() [showProgress, selectedDevices] processedCount is ${processedCount}`);
      setProgress(Math.ceil(processedCount / selectedDevices.length * 100));
      const remaining = selectedDevices.length - processedCount;
      if (remaining === 0)
        setProgressDescription('Complete');
      else
        setProgressDescription(`${remaining} Devices Remaining...`);
    }
  }, [showProgress, selectedDevices]);

  useEffect(() => {
    if (progress < 100 || delay.value > 0) return;
    debug(`ChangeStatus() useEffect() [progress] progress is ${progress}`);
    const selectedDevicesCopy = [...selectedDevices];
    selectedDevicesCopy.sort((a, b) => {
      if (b.setDeviceMaskStatus !== DeviceMutationStatuses.failure && a.setDeviceMaskStatus === DeviceMutationStatuses.failure) return 0;
      return 1;
    });
    debug(`ChangeStatus() useEffect() [progress] selectedDevicesCopy is ${JSON.stringify(selectedDevicesCopy)}`);
    setSelectedDevices(selectedDevicesCopy);
  }, [progress]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    selectedDevices,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No devices"
            subtitle="No devices to display."
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 5 },
      sorting: {},
      selection: {},
    }
  );

  const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
  };

  function maskedComparator(a: boolean | undefined | null, b: boolean | undefined | null): number {
    const getTextVal = (masked: boolean | undefined | null) => masked ? 'Masked' : (masked === null || masked === undefined) ? 'Unknown' : 'Unmasked';

    const aMaskedText = getTextVal(a);
    const bMaskedText = getTextVal(b);
    return (aMaskedText.localeCompare(bMaskedText))
  };

  useEffect(() => {
    // automatically confirm for single device selection
    debug(`ChangeStatus() useEffect() [] props.singleSelection is ${JSON.stringify(props.singleSelection)} newStatus is ${JSON.stringify(newStatus)} items.length is ${items.length}`);
    if (props.singleSelection && newStatus?.value && items.length > 0) confirmBtnHandler();
  }, [])

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      footer={
        <Box float={'right'}>
          <Button
            variant="primary"
            onClick={() => {
              if (progress === 0) confirmBtnHandler();
              if (progress >= 100) props.setChangeStatusVisibleCallback(false);
            }}
            disabled={(props.deviceType === 'readersAlarms' && !maskedEvent) || !newStatus?.value || showProgress && progress < 100 || (delay.value > 0 && !validEmailAddresses(emailAddresses))}
          >
            {progress < 100 ? bundle.getMessage('confirm') : bundle.getMessage('ok')}
          </Button>
        </Box>
      }
      header={bundle.formatMessage('change-status', { selectedCount: selectedDevices.length, total: props.totalDevicesCount })}
      onDismiss={() => props.setChangeStatusVisibleCallback(false)}
      size={props.deviceType === 'inputs' ? 'large' : 'max'}
      visible={props.changeStatusVisible}
    >
      {
        showProgress &&
        <ProgressBar
          status="in-progress"
          value={progress}
          additionalInfo=""
          description={progressDescription}
          label={bundle.formatMessage('update-status', { newStatus: newStatus.label })}
        />
      }
      {
        <Table
          {...collectionProps}
          trackBy='DeviceName'
          ariaLabels={{
            selectionGroupLabel: bundle.getMessage('devices-selection'),
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`,
            itemSelectionLabel: ({ selectedItems }, item: any) => {
              const isItemSelected = selectedItems.filter(
                (i: any) => i.name === item.name
              ).length;
              return `${item.name} is ${isItemSelected ? "" : "not"
                } selected`;
            }
          }}
          columnDefinitions={[
            {
              id: "deviceName",
              header: bundle.getMessage('device-name'),
              cell: (e: any) => e.DeviceName,
              sortingField: "DeviceName"
            },
            {
              id: "pairedReader",
              header: bundle.getMessage('paired-reader'),
              cell: (e: any) => e.PairedReader,
              sortingField: "PairedReader"
            },
            {
              id: "maskedStatus",
              header: bundle.getMessage('masked'),
              cell: (e: any) => {
                const translateKey = e.Masked ? 'masked' : (e.Masked === null || e.Masked === undefined) ? 'unknown' : 'unmasked';
                return <TranslateText translateKey={translateKey} />;
              },
              sortingField: 'maskedStatus',
              sortingComparator: (a: any, b: any) => maskedComparator(a.Masked, b.Masked)
            },
            {
              id: "doorForcedMasked",
              header: bundle.getMessage('door-forced'),
              cell: (e: any) => {
                const translateKey = e.DoorForcedMasked ? 'masked' : (e.DoorForcedMasked === null || e.DoorForcedMasked === undefined) ? 'unknown' : 'unmasked';
                return <TranslateText translateKey={translateKey} />;
              },
              sortingField: 'doorForcedMasked',
              sortingComparator: (a: any, b: any) => maskedComparator(a.DoorForcedMasked, b.DoorForcedMasked)
            },
            {
              id: "doorHeldMasked",
              header: bundle.getMessage('door-held'),
              cell: (e: any) => {
                const translateKey = e.DoorHeldMasked ? 'masked' : (e.DoorHeldMasked === null || e.DoorHeldMasked === undefined) ? 'unknown' : 'unmasked';
                return <TranslateText translateKey={translateKey} />;
              },
              sortingField: 'doorHeldMasked',
              sortingComparator: (a: any, b: any) => maskedComparator(a.DoorHeldMasked, b.DoorHeldMasked)
            },
            {
              id: "setDeviceMaskStatus",
              header: bundle.getMessage('status'),
              cell: (e: any) => {
                switch (e.setDeviceMaskStatus) {
                  case DeviceMutationStatuses.updated:
                    return <span style={{ color: 'green' }}><TranslateText translateKey={e.setDeviceMaskStatus} /></span>
                  case DeviceMutationStatuses.failure:
                    return <span style={{ color: 'red' }}><TranslateText translateKey={e.setDeviceMaskStatus} /></span>
                  case DeviceMutationStatuses.submitted:
                    return <span style={{ color: 'green' }}><TranslateText translateKey={e.setDeviceMaskStatus} /></span>
                  default:
                    return <TranslateText translateKey={e.setDeviceMaskStatus} />
                }
              },
              sortingField: "setDeviceMaskStatus"
            }
          ]}
          header={
            <SpaceBetween size="xs" direction="horizontal">
              {props.deviceType == 'readersAlarms' &&
                <FormField label={bundle.getMessage('event')}>
                  <Select
                    disabled={disableSelectMode}
                    expandToViewport
                    invalid={!maskedEvent}
                    onChange={({ detail }) => {
                      setMaskedEvent(detail.selectedOption);
                    }}
                    options={[
                      { value: 'Door Forced Open', label: 'Door Forced Open' },
                      { value: 'Door Held Open', label: 'Door Held Open' }
                    ]}
                    placeholder={bundle.getMessage('select-an-event')}
                    selectedAriaLabel={bundle.getMessage('selected')}
                    selectedOption={maskedEvent}
                  />
                </FormField>
              }
              <FormField label={bundle.getMessage('new-status')}>
                <Select
                  disabled={disableSelectMode}
                  expandToViewport
                  invalid={!newStatus || !newStatus.value}
                  onChange={({ detail }) => {
                    setNewStatus(detail.selectedOption);
                  }}
                  options={Statuses}
                  placeholder={bundle.getMessage('select-a-status')}
                  selectedAriaLabel={bundle.getMessage('selected')}
                  selectedOption={newStatus}
                />
              </FormField>
              <FormField label={bundle.getMessage('delay')}>
                <Select
                  disabled={confirmed || (props.deviceType === 'readersAlarms' && onguardSite)}
                  expandToViewport
                  onChange={({ detail }) => {
                    setDelay(detail.selectedOption)
                  }}
                  options={DelayOptions}
                  selectedAriaLabel={bundle.getMessage('selected')}
                  selectedOption={props.deviceType === 'readersAlarms' && onguardSite ? { label: '2 minutes', values: '2' } : delay}
                />
              </FormField>
              <FormField label={bundle.getMessage('email-addresses')}>
                <Input
                  disabled={(delay.value == 0 && !(props.deviceType === 'readersAlarms' && onguardSite)) || confirmed}
                  invalid={!emailAddresses || !validEmailAddresses(emailAddresses)}
                  onChange={({ detail }) => setEmailAddresses(detail.value)}
                  placeholder={bundle.getMessage('email-addresses')}
                  value={emailAddresses}
                />
              </FormField>
            </SpaceBetween>
          }
          items={items}
          visibleColumns={
            props.deviceType === 'inputs' ?
              [
                'deviceName',
                'maskedStatus',
                'setDeviceMaskStatus'
              ] :
              [
                'deviceName',
                'pairedReader',
                'doorForcedMasked',
                'doorHeldMasked',
                'setDeviceMaskStatus'
              ]
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={paginationLabels}
            />
          }
        />
      }
    </Modal>
  );

}