import * as APIt from '../../API';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CollectionPreferences,
    Flashbar,
    Header,
    Icon,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Spinner,
    Table
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { ProgrammedScheduleColumnDefinitions, ProgrammedScheduleFilteringOptions, ProgrammedScheduleFilteringProperties, ProgrammedScheduleRow } from './programed-schedules-table-config';
import { PaginationLabels, TableEmptyState, TableNoMatchState } from '../table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { debug, i18nFilterStrings } from 'src/utils/commonUtils';
import { listProgrammedSchedules } from 'src/graphql/queries';

interface ProgrammedSchedulesTableProps {
    pageSize: number;
    siteCode?: string;
    onPageSizeChange?: (newPageSize: number) => void;
}

const extractTimeFromDateTime = (dateTimeStr: string): string => {
    try {
        const date = new Date(dateTimeStr);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (e) {
        console.error('Error parsing date:', e);
        return dateTimeStr;
    }
};

export const ProgrammedSchedulesTable: React.FC<ProgrammedSchedulesTableProps> = ({ pageSize, siteCode, onPageSizeChange }) => {
    const [bundle, isBundleLoading] = useBundle('components.schedules.Schedules');
    const [programmedSchedules, setprogrammedSchedules] = useState<ProgrammedScheduleRow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [flashBarMessages, setFlashBarMessages] = React.useState<any>([{
        type: "warning",
        content: (
            <>
                Caution: Read-Only: The schedules listed here were created on <b>PACS devices</b>, schedules created via <b>Silencio</b> are displayed in the <b>Schedules</b> tab
            </>
        ),
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setFlashBarMessages([]),
        id: "programmingMessage"
    }]);

    const fetchProgrammedSchedules = async () => {
        if (!siteCode) {
            setprogrammedSchedules([]);
            return;
        }
        
        setLoading(true);
        try {
            const response = await API.graphql(graphqlOperation(listProgrammedSchedules, {
                scheduleAction: APIt.ScheduleAction.listProgrammed,
                input: {
                    limit:100,
                    offset:0,
                    siteCode: siteCode
                },
            })) as GraphQLResult<APIt.ListProgrammedSchedulesQuery>;

            if (response.data?.listProgrammedSchedules?.result?.schedules) {
                const parsedSchedules = response.data.listProgrammedSchedules.result.schedules.map(
                    scheduleStr => JSON.parse(scheduleStr!)
                );

                const formattedSchedules = parsedSchedules.map(item => ({
                    deviceName: item.deviceName,
                    deviceType: item.deviceType,
                    scheduleName: item.timezone_name,
                    start: extractTimeFromDateTime(item.start),
                    end: extractTimeFromDateTime(item.end),
                    startMode: item.start_mode,
                    endMode: item.end_mode,
                    sunday: item.sunday,
                    monday: item.monday,
                    tuesday: item.tuesday,
                    wednesday: item.wednesday,
                    thursday: item.thursday,
                    friday: item.friday,
                    saturday: item.saturday,
                    timezone_type_flag: item.timezone_type_flag,
                    line: item.line
                }));

                setprogrammedSchedules(formattedSchedules);
            } else {
                setprogrammedSchedules([]);
                if (response.data?.listProgrammedSchedules?.error) {
                    setErrorMessage('Failed to load programmed schedules');
                }
            }
        } catch (err) {
            console.error('Error fetching programmed schedules:', err);
            setErrorMessage('Failed to load programmed schedules');
        } finally {
            setLoading(false);
        }
    };

    const { items: items, actions, collectionProps: programmedSchedulesCollectionProps, paginationProps: programmedSchedulesPaginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
        programmedSchedules,
        {
            pagination: { pageSize: pageSize },
            sorting: { 
                defaultState: { 
                    sortingColumn: { 
                        sortingField: 'scheduleName' 
                    } 
                } 
            },
            filtering: {
                empty: bundle?.getMessage('no-schedules') || 'No schedules found',
                noMatch: bundle?.getMessage('no-schedules') || 'No schedules found'
            },
            propertyFiltering: {
                filteringProperties: ProgrammedScheduleFilteringProperties,
                empty: <TableEmptyState title={bundle?.getMessage('no-schedules') || 'No schedules found'} />,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                        }}
                    />
                ),
                defaultQuery: { tokens: [], operation: 'and' }
            }
        }
    );

    const handlePageSizeChange = (detail: { pageSize?: number }) => {
        if (detail.pageSize !== undefined && onPageSizeChange) {
            onPageSizeChange(detail.pageSize);
        }
    };

    useEffect(() => {
        debug('ProgrammedSchedules() useEffect() loading schedules');
        fetchProgrammedSchedules()
    }, [siteCode]);
    
    if (isBundleLoading) return <Spinner />;
    
    
    return (
        <Box padding={{ top: 'l' }}>
            {errorMessage &&
                <Alert
                    dismissible={true}
                    type='error'
                    visible={errorMessage != null}
                    onDismiss={() => setErrorMessage(null)}
                >
                    {errorMessage}
                </Alert>
            }
            <Table
                {...programmedSchedulesCollectionProps}
                ariaLabels={{
                    tableLabel: 'Programmed Schedules Table'
                }}
                columnDefinitions={ProgrammedScheduleColumnDefinitions}
                empty={<TableEmptyState title="No programmed schedules" />}
                filter={
                    <PropertyFilter
                        {...propertyFilterProps}
                        filteringOptions={ProgrammedScheduleFilteringOptions}
                        i18nStrings={{
                            ...i18nFilterStrings,
                            filteringAriaLabel: bundle.getMessage('filter-schedules'),
                            filteringPlaceholder: bundle.getMessage('filter-schedules')
                        }}
                        countText={bundle.formatMessage('filter-matches', { count: filteredItemsCount || 0 })}
                        expandToViewport={true}
                    />
                }
                header={
                    <>
                        <Header
                            counter={loading ? `(${bundle.getMessage('loading-programmed-schedules')})` : `(${items.length})`}
                        >
                            {bundle.getMessage('programmed-schedules') || 'Programmed Schedules'}
                        </Header>
                        <SpaceBetween direction='vertical' size='xs'>
                            <Flashbar
                                items={flashBarMessages}
                            />
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button onClick={() => fetchProgrammedSchedules()} ariaLabel={bundle.getMessage('refresh-schedules')}>
                                    <Icon name='refresh' alt={bundle.getMessage('refresh-schedules')} />
                                </Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    </> 
                }
                items={items}
                loading={loading}
                loadingText={bundle.getMessage('loading-programmed-schedules')}
                pagination={
                    <Pagination
                        {...programmedSchedulesPaginationProps}
                        ariaLabels={PaginationLabels}
                    />
                }
                resizableColumns={true}
                stickyHeader={true}
                trackBy='scheduleName'
                preferences={
                    <CollectionPreferences
                        id="imported-schedules-preferences"
                        onConfirm={({ detail }) => handlePageSizeChange(detail)}
                        title={bundle.getMessage('preferences') || "Preferences"}
                        confirmLabel={bundle.getMessage('confirm') || "Confirm"}
                        cancelLabel={bundle.getMessage('cancel') || "Cancel"}
                        preferences={{
                            pageSize
                        }}
                        pageSizePreference={{
                            title: bundle.getMessage('select-page-size') || "Select page size",
                            options: [
                                { value: 20, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 20 }) },
                                { value: 50, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 50 }) },
                                { value: 100, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 100 }) },
                                { value: 150, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 150 }) },
                                { value: 200, label: bundle.formatMessage('number-of-schedules', { numberOfSchedules: 200 }) },
                            ],
                        }}
                    />
                }
            />
        </Box>
    );
};

export default ProgrammedSchedulesTable;
