import * as APIt from "../API";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import {
    deleteSchedule as deleteScheduleMutation,
    setSchedule as setScheduleMutation,
    updateSchedule as updateScheduleMutation,
    editSchedule as editScheduleMutation
} from '../graphql/mutations'
import { UserActionNames } from 'src/constants/Constants';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { debug } from './commonUtils';
import { listSchedules as listSchedulesQuery } from '../graphql/queries'

export const setSchedule = async (input: APIt.SetScheduleInput, requestedBy: string): Promise<APIt.ScheduleResponse> => {
    debug(`setSchedule() Setting new schedule with expression: ${input.scheduleExpression}`);
    if (input.amzn_key) {
        debug(`setSchedule() Setting new schedule with device: ${input.amzn_key}`);
    } else if (input.maskingGroupName && input.maskingGroupType) {
        debug(`setSchedule() Setting new schedule with masking group: ${JSON.stringify(input.maskingGroupName)}`);
    } else {
        throw new Error(`setSchedule() schedule missing device or masking group: ${JSON.stringify(input)}`);
    }

    try {
        createUserAction({
            actionName: UserActionNames.SetSchedule,
            username: requestedBy,
            parameters: JSON.stringify({ input })
        });
        const response = await API.graphql(graphqlOperation(setScheduleMutation, {
            scheduleAction: APIt.ScheduleAction.set,
            input,
        })) as GraphQLResult<APIt.SetScheduleMutation>;

        debug(`Set schedule response is ${JSON.stringify(response)}`);
        createUserAction({
            actionName: UserActionNames.SetScheduleResult,
            username: requestedBy,
            parameters: JSON.stringify({ input, response })
        });

        if (response.data?.setSchedule?.error) {
            debug(`Set schedule response has an error: ${response.data.setSchedule.error}`);
        }
        if (response.data?.setSchedule?.status != 200) {
            throw new Error(`setSchedule() response has invalid status: ${response.data?.setSchedule?.status}`);
        }
        return response.data?.setSchedule as APIt.ScheduleResponse;
    }
    catch (error) {
        debug(`setSchedule() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        createUserAction({
            actionName: UserActionNames.SetScheduleError,
            username: requestedBy,
            parameters: JSON.stringify({ error, input })
        });
        throw error;
    }
}

export const listSchedules = async (input: APIt.ListSchedulesInput, requestedBy: string): Promise<APIt.ListSchedulesOutput> => {
    debug(`Listing schedules for: ${input.NamePrefix}`);

    try {
        createUserAction({
            actionName: UserActionNames.ListSchedules,
            username: requestedBy,
            parameters: JSON.stringify({ input })
        });
        const response = await API.graphql(graphqlOperation(listSchedulesQuery, {
            scheduleAction: APIt.ScheduleAction.list,
            input,
        })) as GraphQLResult<APIt.ListSchedulesQuery>;

        debug(`List schedules response is ${JSON.stringify(response)}`);
        createUserAction({
            actionName: UserActionNames.ListSchedulesResult,
            username: requestedBy,
            parameters: JSON.stringify({ input, response })
        });

        if (response.data?.listSchedules?.error) {
            throw new Error(`List schedules response has an error: ${response.data.listSchedules.error}`);
        }
        if (response.data?.listSchedules?.status != 200) {
            throw new Error(`List schedules response has invalid status ${response.data?.listSchedules?.status}`);
        }
        return response.data?.listSchedules?.result as APIt.ListSchedulesOutput;
    }
    catch (error) {
        debug(`listSchedules() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        createUserAction({
            actionName: UserActionNames.ListSchedulesError,
            username: requestedBy,
            parameters: JSON.stringify({ error, input })
        });
        throw error;
    }
}

export const deleteSchedule = async (input: APIt.DeleteScheduleInput, requestedBy: string): Promise<string> => {
    debug(`Deleting schedule ${input.scheduleName}`);

    try {
        createUserAction({
            actionName: UserActionNames.DeleteSchedule,
            username: requestedBy,
            parameters: JSON.stringify({ input })
        });
        const response = await API.graphql(graphqlOperation(deleteScheduleMutation, {
            scheduleAction: APIt.ScheduleAction.delete,
            input,
        })) as GraphQLResult<APIt.DeleteScheduleMutation>;

        debug(`Delete schedule response is ${JSON.stringify(response)}`);
        createUserAction({
            actionName: UserActionNames.DeleteScheduleResult,
            username: requestedBy,
            parameters: JSON.stringify({ input, response })
        });

        if (response.data?.deleteSchedule?.error) {
            throw new Error(`Delete schedule response has an error: ${response.data.deleteSchedule.error}`);
        }
        if (response.data?.deleteSchedule?.status != 200) {
            throw new Error(`Delete schedule response has invalid status ${response.data?.deleteSchedule?.status}`);
        }
        return response.data?.deleteSchedule?.message as string;
    }
    catch (error) {
        debug(`deleteSchedule() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        createUserAction({
            actionName: UserActionNames.DeleteScheduleError,
            username: requestedBy,
            parameters: JSON.stringify({ error, input })
        });
        throw error;
    }
}

export const updateSchedule = async (input: APIt.UpdateScheduleInput, requestedBy: string): Promise<string> => {
    debug(`Updating schedule ${input.scheduleName} with enabled: ${input.enabled}`);
    try {
        createUserAction({
            actionName: UserActionNames.UpdateSchedule,
            username: requestedBy,
            parameters: JSON.stringify({ input })
        });
        const response = await API.graphql(graphqlOperation(updateScheduleMutation, {
            scheduleAction: APIt.ScheduleAction.update,
            input,
        })) as GraphQLResult<APIt.UpdateScheduleMutation>;

        debug(`Update schedule response is ${JSON.stringify(response)}`);
        createUserAction({
            actionName: UserActionNames.UpdateScheduleResult,
            username: requestedBy,
            parameters: JSON.stringify({ input, response })
        });

        if (response.data?.updateSchedule?.error) {
            throw new Error(`Update schedule response has an error: ${response.data.updateSchedule.error}`);
        }
        if (response.data?.updateSchedule?.status != 200) {
            throw new Error(`Update schedule response has invalid status: ${response.data?.updateSchedule?.status}`);
        }
        return response.data?.updateSchedule?.message as string;
    }
    catch (error) {
        debug(`updateSchedule() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        createUserAction({
            actionName: UserActionNames.UpdateScheduleError,
            username: requestedBy,
            parameters: JSON.stringify({ error, input })
        });
        throw error;
    }
}

export const editSchedule = async (input: APIt.EditScheduleInput, requestedBy: string): Promise<APIt.ScheduleResponse> => {
    debug(`Editing schedule ${input.scheduleName} with enabled: ${input.enabled}`);
    try {
        createUserAction({
            actionName: UserActionNames.EditSchedule,
            username: requestedBy,
            parameters: JSON.stringify({ input })
        });
        const response = await API.graphql(graphqlOperation(editScheduleMutation, {
            scheduleAction: APIt.ScheduleAction.edit,
            input,
        })) as GraphQLResult<APIt.EditScheduleMutation>;

        debug(`Update schedule response is ${JSON.stringify(response)}`);
        createUserAction({
            actionName: UserActionNames.EditScheduleResult,
            username: requestedBy,
            parameters: JSON.stringify({ input, response })
        });

        if (response.data?.editSchedule?.error) {
            throw new Error(`Edit schedule response has an error: ${response.data.editSchedule.error}`);
        }
        if (response.data?.editSchedule?.status != 200) {
            throw new Error(`Edit schedule response has invalid status: ${response.data?.editSchedule?.status}`);
        }
        return response.data?.editSchedule as APIt.ScheduleResponse;
    }
    catch (error) {
        debug(`editSchedule() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        createUserAction({
            actionName: UserActionNames.EditScheduleError,
            username: requestedBy,
            parameters: JSON.stringify({ error, input })
        });
        throw error;
    }
}