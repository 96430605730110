import React, { useState } from 'react';
import {
NotificationType,
Notification,
} from '../API';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { getNotifications } from '../utils/commonUtils';
import { useQuery } from 'react-query';
import { Link } from '@amzn/awsui-components-react';
	 
export function Notifications() {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [error, setError] = useState<string>('');

    useQuery<Notification[]>(
        ['notifications'],
        () => getNotifications(),
        {
        onError: (error) => {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
        },
        onSettled: (data) => {
            if (!data) {
            setNotifications([]);
            return;
            }
            setNotifications(data);
        },
        refetchOnWindowFocus: false,
        retry: 3,
        },
    );

    return (
        <div>
        {notifications.length > 0
        &&
        <Flashbar
        items={
            notifications
            .sort((a,b) => {
                const aType = a.type === NotificationType.CRITICAL
                ? 0
                : a.type === NotificationType.WARNING
                    ? 1
                    : 2;
                const bType = b.type === NotificationType.CRITICAL
                ? 0
                : b.type === NotificationType.WARNING
                    ? 1
                    : 2;
                return aType < bType ? -1 : 1;
            })
            .map((n: Notification, index) => {
                const content =
                <>
                    {String(n.message)}
                    {n.links?.map(l => 
                    <>
                        <br/>
                        <Link
                        color='inverted'
                        external href={l!.target}
                        target='_blank'
                        >
                        {l!.text}
                        </Link>
                    </>)}
                </>;
                return({
                content,
                dismissible: n.dismissable,
                header: n.type,
                onDismiss: () => setNotifications([...notifications.filter((_, i) => i !== index)]),
                type: n.type === NotificationType.CRITICAL
                    ? 'error'
                    : n.type === NotificationType.WARNING
                    ? 'warning'
                    : 'info',
                })
            })
            }
            stackItems={notifications.length > 2}
        />}
        {error !== ''
        &&
        <Alert
        dismissible
        onDismiss={() => setError('')}
        type='error'
        >
        {error}
        </Alert>}
        </div>
    );
}