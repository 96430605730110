import React from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import TranslateText from '../TranslateText';
import { Box, PropertyFilterProps } from '@amzn/awsui-components-react';

// Define the interface for imported schedule rows based on GraphQL response
export interface ProgrammedScheduleRow {
    scheduleName: string; // maps to timezone_name
    deviceName: string;
    deviceType: string;
    start: string;
    end: string;
    startMode?: string;
    endMode?: string;
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    timezone_type_flag?: number;
    line?: number;
}

// Helper function to style the cell content
const DayCell = ({ value }: { value: number }) => (
    <Box textAlign="center" color={value === 1 ? "text-status-success" : "text-status-inactive"}>
        {value === 1 ? '✓' : '-'}
    </Box>
);


export const ProgrammedScheduleColumnDefinitions: TableProps.ColumnDefinition<ProgrammedScheduleRow>[] = [
    {
        id: 'scheduleName',
        header: <TranslateText translateKey={'schedule-name'} />,
        cell: (item: ProgrammedScheduleRow) => item.scheduleName,
        sortingField: 'scheduleName'
    },
    {
        id: 'deviceName',
        header: <TranslateText translateKey={'device-name'} />,
        cell: (item: ProgrammedScheduleRow) => item.deviceName || '-',
        sortingField: 'deviceName'
    },
    {
        id: 'deviceType',
        header: <TranslateText translateKey={'device-type'} />,
        cell: (item: ProgrammedScheduleRow) => item.deviceType || '-',
        sortingField: 'deviceType'
    },
    {
        id: 'start',
        header: <TranslateText translateKey={'start'} />,
        cell: (item: ProgrammedScheduleRow) => item.start,
        sortingField: 'start'
    },
    {
        id: 'end',
        header: <TranslateText translateKey={'end'} />,
        cell: (item: ProgrammedScheduleRow) => item.end,
        sortingField: 'end'
    },
    {
        id: 'startMode',
        header: <TranslateText translateKey={'start-mode'} />,
        cell: (item: ProgrammedScheduleRow) => item.startMode || '-',
        sortingField: 'startMode'
    },
    {
        id: 'endMode',
        header: <TranslateText translateKey={'end-mode'} />,
        cell: (item: ProgrammedScheduleRow) => item.endMode || '-',
        sortingField: 'endMode'
    },
    {
        id: 'sunday',
        header: <TranslateText translateKey={'sunday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.sunday} />,
        sortingField: 'sunday'
    },
    {
        id: 'monday',
        header: <TranslateText translateKey={'monday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.monday} />,
        sortingField: 'monday'
    },
    {
        id: 'tuesday',
        header: <TranslateText translateKey={'tuesday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.tuesday} />,
        sortingField: 'tuesday'
    },
    {
        id: 'wednesday',
        header: <TranslateText translateKey={'wednesday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.wednesday} />,
        sortingField: 'wednesday'
    },
    {
        id: 'thursday',
        header: <TranslateText translateKey={'thursday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.thursday} />,
        sortingField: 'thursday'
    },
    {
        id: 'friday',
        header: <TranslateText translateKey={'friday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.friday} />,
        sortingField: 'friday'
    },
    {
        id: 'saturday',
        header: <TranslateText translateKey={'saturday'} />,
        cell: (item: ProgrammedScheduleRow) => <DayCell value={item.saturday} />,
        sortingField: 'saturday'
    }
];

export const ProgrammedScheduleFilteringProperties: PropertyFilterProps.FilteringProperty[] = [
    {
        key: 'deviceName',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Device Name',
        groupValuesLabel: 'Device Names'
    },
    {
        key: 'scheduleName',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Schedule Name',
        groupValuesLabel: 'Schedule Names'
    },
    {
        key: 'deviceType',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Device Type',
        groupValuesLabel: 'Device Types'
    }
];
export const ProgrammedScheduleFilteringOptions = [
    {
        propertyKey: 'deviceName',
        operator: ':',
        value: ''
    },
    {
        propertyKey: 'scheduleName',
        operator: ':',
        value: ''
    },
    {
        propertyKey: 'deviceType',
        operator: ':',
        value: ''
    }
];
