import { AppLayout, Modal, SpaceBetween, Spinner, Tabs } from '@amzn/awsui-components-react';
import { DEFAULT_LC_BUILDER, ILanguage } from "../constants/Constants";
import { DeviceTypes, MaskingGroupTypes } from "src/constants/Constants";
import { MaskingGroupInfo, SilencioDevice } from "src/utils/SilencioTypes";
import React, { useEffect, useState } from 'react';
import {
  addOrUpdateDevice,
  addPlaceholderDevice,
  consolidatePairedReaders,
  fetchDevices,
  fetchIORelations
} from '../utils/DevicesUtils';
import {
  selectUsername,
  selectUserPrefs,
  selectUserSelectedSitePrivs,
  selectUserSelectedSite
} from '../stores/slices/userSlice';
import {
  setInputsDevices,
  setLoadingDevices,
  setOutputsDevices,
  setReadersAlarmsDevices,
  setReadersModesDevices,
  setSelectedReadersModes,
  setSelectedInputs,
  setSelectedOutputs,
  setSelectedReadersAlarms,
  selectSelectedInputs,
  selectSelectedReadersAlarms,
  selectSelectedReadersModes,
  updateSelectedInputMask,
  updateSelectedOutputStatus,
  updateSelectedReaderMasked,
  updateSelectedReaderMode,
  setInputsMaskingGroups,
  setReadersAlarmsMaskingGroups,
  setReadersModesMaskingGroups
} from '../stores/slices/devicesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import { IsItDown } from './navigation/IsItDown';
import { Privileges } from 'src/utils/UserSitesUtils';
import { QuickActions } from './templates/QuickActions';
import Schedules from './schedules';
import SelectSite from './navigation/SelectSite';
import SiteDevices from './SiteDevices';
import TemplatesTable from './templates';
import { TopNav } from './navigation/TopNav';
import Tracker from './Tracker';
import { debug } from '../utils/commonUtils';
import i18n from "src/i18n";
import messages from '@amzn/awsui-components-react/polaris/i18n/messages/all.all';
import { useBundle } from '@amzn/react-arb-tools';
import { useLocalizationContext } from "@amzn/react-arb-tools";
import { QueryClient, QueryClientProvider } from 'react-query';

export default function App(props: any) {
  debug(`App() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.App');

  const dispatch = useDispatch();

  const { localizationContext, setLocalizationContext } = useLocalizationContext();

  const selectedInputs = useSelector(selectSelectedInputs);
  const selectedOutputs = useSelector(selectSelectedInputs);
  const selectedReadersAlarms = useSelector(selectSelectedReadersAlarms);
  const selectedReadersModes = useSelector(selectSelectedReadersModes);
  const username = useSelector(selectUsername);
  const userPrefs = useSelector(selectUserPrefs);
  const userSelectedSite = useSelector(selectUserSelectedSite);
  const userSelectedSitePrivs = useSelector(selectUserSelectedSitePrivs);

  const [darkMode, setDarkMode] = useState<boolean>(props.darkMode);
  const [loadingSites, setLoadingSites] = useState<boolean>(false);
  const [siteSelectVisible, setSiteSelectVisible] = useState<boolean>(false);

  async function refreshDevices() {
    debug(`App() refreshDevices() userSelectedSite.sitename is ${userSelectedSite.sitename} userSelectedSite.siteRegion is ${userSelectedSite.siteRegion}`);
    dispatch(setLoadingDevices(true));
    try {
      const inputs: SilencioDevice[] = [];
      const outputs: SilencioDevice[] = [];
      const readersAlarms: SilencioDevice[] = [];
      const readersModes: SilencioDevice[] = [];
      const inputsMaskingGroups: MaskingGroupInfo[] = [];
      const readersAlarmsMaskingGroups: MaskingGroupInfo[] = [];
      const readersModesMaskingGroups: MaskingGroupInfo[] = [];

      const returnedDevices = await fetchDevices(userSelectedSite.sitename, userSelectedSite.siteRegion);

      returnedDevices.forEach((d: SilencioDevice) => {
        if (d.MaskingGroups && d.MaskingGroups.length) {
          const maskingGroup: MaskingGroupInfo = d.MaskingGroups[0];
          switch (maskingGroup.type) {
            case MaskingGroupTypes.Inputs:
              if (!inputsMaskingGroups.find(mg => mg.id == maskingGroup.id)) inputsMaskingGroups.push(maskingGroup);
              break;
            case MaskingGroupTypes.ReadersAlarms:
              if (!readersAlarmsMaskingGroups.find(mg => mg.id == maskingGroup.id)) readersAlarmsMaskingGroups.push(maskingGroup);
              break;
            case MaskingGroupTypes.ReadersModes:
              if (!readersModesMaskingGroups.find(mg => mg.id == maskingGroup.id)) readersModesMaskingGroups.push(maskingGroup);
              break;
            default:
              debug(`Error: App() refreshDevices() unhandled masking group type: ${maskingGroup.type}`);
              break;
          }
        }

        if (DeviceTypes.Inputs.includes(d.Device_Type!)) {
          addOrUpdateDevice(inputs, d);
        } else if (DeviceTypes.Outputs.includes(d.Device_Type!)) {
          addOrUpdateDevice(outputs, d);
        } else if (DeviceTypes.Readers.includes(d.Device_Type!)) {
          if (!d.MaskingGroups || d.MaskingGroups.length == 0) {
            addOrUpdateDevice(readersAlarms, d);
            addOrUpdateDevice(readersModes, d);
          } else {
            if (d.MaskingGroups[0].type === MaskingGroupTypes.ReadersAlarms) {
              addOrUpdateDevice(readersAlarms, d);
              addPlaceholderDevice(readersModes, d);
            } else if (d.MaskingGroups[0].type === MaskingGroupTypes.ReadersModes) {
              addOrUpdateDevice(readersModes, d);
              addPlaceholderDevice(readersAlarms, d);
            } else {
              debug(`Error: App() refreshDevices() unhandled masking group type: ${d.MaskingGroups[0].type}`);
            }
          }
        }
      });

      const consolidatedReadersAlarms = consolidatePairedReaders(readersAlarms);
      const inputsWithIORelations = await fetchIORelations(inputs, outputs, userSelectedSite.sitename, userSelectedSite.siteRegion);

      dispatch(setInputsDevices({ inputs: inputsWithIORelations }));
      dispatch(setInputsMaskingGroups({ maskingGroups: inputsMaskingGroups.sort((a, b) => a.name > b.name ? 1 : -1) }));
      debug(`App() refreshDevices() selectedInputs.length is ${selectedInputs.length} inputs.length is ${inputs.length}`);
      if (selectedInputs && selectedInputs.length) inputs.forEach(input => dispatch(updateSelectedInputMask(input)));
      dispatch(setOutputsDevices({ outputs: outputs }));
      if (selectedOutputs && selectedOutputs.length) outputs.forEach(output => dispatch(updateSelectedOutputStatus(output)));
      dispatch(setReadersAlarmsDevices({ readersAlarms: consolidatedReadersAlarms }));
      dispatch(setReadersAlarmsMaskingGroups({ maskingGroups: readersAlarmsMaskingGroups.sort((a, b) => a.name > b.name ? 1 : -1) }));
      if (selectedReadersAlarms && selectedReadersAlarms.length) readersAlarms.forEach(reader => dispatch(updateSelectedReaderMasked(reader)));
      dispatch(setReadersModesDevices({ readersModes: readersModes }));
      dispatch(setReadersModesMaskingGroups({ maskingGroups: readersModesMaskingGroups.sort((a, b) => a.name > b.name ? 1 : -1) }));
      if (selectedReadersModes && selectedReadersModes.length) readersModes.forEach(reader => dispatch(updateSelectedReaderMode(reader)));
    } catch (error) {
      console.error(`refreshDevices() error is ${error} JSON.stringify is ${JSON.stringify(error)}`);
    }
    dispatch(setLoadingDevices(false));
  };


  useEffect(() => {
    debug(`App() useEffect() [userPrefs]`);
    const sitePrefs = JSON.parse(userPrefs?.site);
    debug(`App() useEffect() [userPrefs] sitePrefs is ${JSON.stringify(sitePrefs)}`);
    if (!sitePrefs) return;

    try {
      const languageStr = JSON.parse(userPrefs.site)['language'];
      if (languageStr) {
        const language: ILanguage = JSON.parse(languageStr);
        i18n.changeLanguage(language.id);
        setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language.id).build());
      }
    } catch (error) {
      console.error(error);
    }
  }, [userPrefs]);

  useEffect(() => {
    if (userSelectedSite) (async () => {
      dispatch(setInputsDevices({ inputs: [] }));
      dispatch(setInputsMaskingGroups({ maskingGroups: [] }));
      dispatch(setOutputsDevices({ outputs: [] }));
      dispatch(setReadersAlarmsDevices({ readersAlarms: [] }));
      dispatch(setReadersAlarmsMaskingGroups({ maskingGroups: [] }));
      dispatch(setReadersModesDevices({ readersModes: [] }));
      dispatch(setReadersModesMaskingGroups({ maskingGroups: [] }));
      dispatch(setSelectedInputs([]));
      dispatch(setSelectedOutputs([]));
      dispatch(setSelectedReadersAlarms([]));
      dispatch(setSelectedReadersModes([]));
      await refreshDevices();
    })();
  }, [userSelectedSite]);

  const refreshUserSites = async () => {
    debug(`App(): refreshUserSites()`);
    setLoadingSites(true);
    try {
      if (props.refreshUserSitesCallback) await props.refreshUserSitesCallback();
    } catch (error) {
      debug(`App(): refreshUserSites() error is ${error} ${typeof error === 'object' ? JSON.stringify(error) : error}`);
    }
    setLoadingSites(false);
    debug(`App(): refreshUserSites() complete`);
  };

  const selectSiteOnDismissHandler = () => {
    setSiteSelectVisible(false);
  };

  const displayTabs = (): { label: string, id: string, content: any }[] => {
    let tabs: { label: string; id: string; content: any; }[] = [];
    if (userSelectedSitePrivs && userSelectedSitePrivs.length > 0 && userSelectedSitePrivs.includes(Privileges.all))
      tabs = [
        {
          label: bundle.getMessage('inputs'),
          id: "inputs",
          content: <SiteDevices darkMode={darkMode} deviceType='inputs' refreshDevicesCallback={refreshDevices} />
        },
        {
          label: bundle.getMessage('outputs'),
          id: "outputs",
          content: <SiteDevices darkMode={darkMode} deviceType='outputs' refreshDevicesCallback={refreshDevices} />
        },
        {
          label: bundle.getMessage('readers-alarms'),
          id: "readersAlarms",
          content: <SiteDevices darkMode={darkMode} deviceType='readersAlarms' refreshDevicesCallback={refreshDevices} />
        },
        {
          label: bundle.getMessage('readers-mode'),
          id: "readersModes",
          content: <SiteDevices darkMode={darkMode} deviceType='readersModes' refreshDevicesCallback={refreshDevices} />
        },
        {
          label: bundle.getMessage('schedules'),
          id: "schedules",
          content: <Schedules refreshDevicesCallback={refreshDevices} />
        },
        {
          label: bundle.getMessage('site-templates'),
          id: "templates",
          content: <TemplatesTable />
        }
      ];
    else
      tabs = [
        {
          label: bundle.getMessage('inputs'),
          id: "inputs",
          content: <SiteDevices darkMode={darkMode} deviceType='inputs' refreshDevicesCallback={refreshDevices} />
        }
      ];
    return tabs;
  };

  const queryClient = new QueryClient();

  if (isBundleLoading) return <Spinner />;

  return (
    <QueryClientProvider client={queryClient}>
      <I18nProvider locale={localizationContext.getLocale()} messages={[messages]}>
        <TopNav
          setDarkMode={setDarkMode}
          setSiteSelectVisibleCallback={setSiteSelectVisible}
          sitename={userSelectedSite?.sitename}
          sitePrivs={userSelectedSitePrivs}
          username={username}
        />
        <AppLayout
          ariaLabels={{ navigationClose: 'close' }}
          content={
            <SpaceBetween size='m' direction='vertical'>
              {
                (userSelectedSitePrivs && userSelectedSitePrivs.length > 0 && userSelectedSitePrivs.includes(Privileges.all)) &&
                <QuickActions refreshDevicesCallback={refreshDevices} />
              }
              <Tabs
                tabs={displayTabs()}
                variant="container"
              />
            </SpaceBetween >
          }
          contentType="table"
          headerSelector="#header"
          navigationHide
          notifications={<IsItDown />}
          stickyNotifications
          toolsHide={true}
        />
        <Tracker />
        <Modal visible={siteSelectVisible} size='medium' header={bundle.getMessage('change-site')} onDismiss={selectSiteOnDismissHandler}>
          <SelectSite
            refreshUserSitesCallback={refreshUserSites}
            selectedSite={userSelectedSite}
            siteRequiredAlertVisible={false}
            siteSelectVisible={siteSelectVisible}
            setSiteSelectVisibleCallback={setSiteSelectVisible}
            loadingSites={loadingSites}
          />
        </Modal>
      </I18nProvider>
    </QueryClientProvider>
  );

}